<template>
    <div id="menubox">
        <div class="menubox_menu" v-show="if_visible">
            <div class="menubox_menu_backg">
                <img v-for="index in 4" :key="index" :src="require('../assets/images/logo_house.svg')" alt="logo_house" />
            </div>
            <div class="menubox_menu_content">
                <div class="mmc_selections">
                    <div
                        class="mmc_selections_selection"
                        @click="$router.push('home')"
                        :class="{'mmc_selections_selection_current':'home'==$route.name}"
                    >
                        <p>主 X 页</p>
                        <p text="home">home</p>
                    </div>
                    <div
                        class="mmc_selections_selection"
                        @click="$router.push('videos')"
                        :class="{'mmc_selections_selection_current':'videos'==$route.name}"
                    >
                        <p>视 X 频</p>
                        <p text="videos">videos</p>
                    </div>
                    <div
                        class="mmc_selections_selection"
                        @click="$router.push('photos')"
                        :class="{'mmc_selections_selection_current':'photos'==$route.name}"
                    >
                        <p>图 X 片</p>
                        <p text="photos">photos</p>
                    </div>
                    <div
                        class="mmc_selections_selection"
                        @click="$router.push('contact')"
                        :class="{'mmc_selections_selection_current':'contact'==$route.name}"
                    >
                        <p>联 X 系</p>
                        <p text="contact">contact</p>
                    </div>
                </div>
                <div class="mmc_contact">
                    <p class="mmc_contact_title">get in touch ///</p>
                    <p class="mmc_contact_message">
                        <span>EMAIL:</span>
                        jie_joe@qq.com
                    </p>
                    <p class="mmc_contact_message">
                        <span>WX:</span>
                        JIEJOE_0821
                    </p>
                    <p class="mmc_contact_message">
                        <span>QQ:</span>
                        1055274233
                    </p>
                </div>
                <a class="mmc_link" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022022313号-2</a>
            </div>
        </div>
        <div class="menubox_logo" @click="$router.push('home')"></div>
        <div class="menubox_navigation">
            <svg
                class="menubox_navigation_menuicon"
                :class="{'menubox_navigation_menuicon_hidden':!menuicon_state,'menubox_navigation_menuicon_show':menuicon_state}"
                @click="change_state"
                viewBox="0 0 50 50"
            >
                <circle cx="25" cy="25" r="30" />
                <line x1="12" y1="25" x2="38" y2="25" />
                <line x1="12" y1="25" x2="38" y2="25" />
            </svg>
            <div class="menubox_navigation_contact" @click="$router.push('contact')" :class="{'menubox_navigation_contact_black':menuicon_state}">
                <p>CONTACT</p>
                <svg viewBox="0 0 50 50">
                    <polyline points="24.4,2.9 46.5,25 24.4,47.1" />
                    <line x1="3.5" y1="25" x2="46.5" y2="25" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
import lottie from "lottie-web";
export default {
    name: "menu",
    data() {
        return {
            // DOM元素
            menu: null,
            selections: [],
            imgs: [],
            menuicon: null,
            // 菜单按钮的显示状态：true为打开，false为关闭，默认为关闭
            menuicon_state: false,
            // logo的动画播放器
            logo_animater: null,
            // 菜单是否可见
            if_visible: false,
            // 菜单是否在进行动画
            if_animating: false,
        };
    },
    methods: {
        init() {
            this.menu = document.querySelector(".menubox_menu");
            this.selections = document.querySelectorAll(".mmc_selections_selection");
            this.menuicon = document.querySelector(".menubox_navigation_menuicon");
            this.imgs = document.querySelectorAll(".menubox_menu_backg img");
            this.creat_logo_animation();
        },
        // 创建logo动画
        creat_logo_animation() {
            const logo = document.querySelector(".menubox_logo");
            this.logo_animater = lottie.loadAnimation({
                container: logo,
                renderer: "svg",
                loop: false,
                autoplay: false,
                animationData: require("../assets/animations/logo_icon.json"),
            });
            // 如果屏幕触点不止一个，则判断为移动端手势，则不绑定logo动画相关的事件
            if (navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) return;
            logo.addEventListener("mouseenter", this.play_logo_animation.bind(this));
            logo.addEventListener("mouseleave", this.invert_logo_animation.bind(this));
        },
        // 正向播放logo动画
        play_logo_animation() {
            this.logo_animater.setDirection(1);
            this.logo_animater.play();
        },
        // 逆向播放logo动画
        invert_logo_animation() {
            this.logo_animater.setDirection(-1);
            this.logo_animater.play();
        },
        // 显示菜单
        show() {
            this.if_animating = true;
            this.if_visible = true;
            this.menuicon_state = true;
            this.lock_scroll(); // 锁定滚动
            gsap.timeline()
                .to(this.menu, {
                    transform: "translateY(0)",
                    "border-top-left-radius": "0",
                    "border-top-right-radius": "0",
                    duration: 1,
                    ease: "power4.out",
                })
                .to(
                    this.selections,
                    {
                        transform: "translateX(0)",
                        duration: 0.8,
                        ease: "power3.out",
                        stagger: 0.1,
                    },
                    "<"
                )
                .to(
                    this.imgs,
                    {
                        transform: "translateY(0)",
                        duration: 0.8,
                        ease: "power3.out",
                        stagger: 0.1,
                        onComplete: () => {
                            this.if_animating = false;
                        },
                    },
                    "<"
                );
        },
        // 隐藏菜单
        hidden() {
            this.if_animating = true;
            this.menuicon_state = false;
            this.unlock_scroll(); // 解除滚动锁定
            gsap.timeline()
                .to(this.selections, {
                    transform: "translateX(-100%)",
                    duration: 0.6,
                    ease: "power4.out",
                    stagger: 0.1,
                })
                .to(
                    this.imgs,
                    {
                        transform: "translateY(100%)",
                        duration: 0.6,
                        ease: "power4.out",
                        stagger: 0.1,
                    },
                    "<"
                )
                .to(
                    this.menu,
                    {
                        transform: "translateY(100%)",
                        "border-top-left-radius": "",
                        "border-top-right-radius": "",
                        duration: 0.8,
                        ease: "power3.out",
                        onComplete: () => {
                            this.if_visible = false;
                            this.if_animating = false;
                        },
                    },
                    "<0.3"
                );
        },
        // 当菜单在显示状态下发生页面跳转时使用
        reload() {
            this.if_visible = false;
            this.if_animating = false;
            this.menuicon_state = false;
            // 恢复菜单为初始状态
            gsap.timeline()
                .set(this.selections, {
                    transform: "translateX(-100%)",
                })
                .set(this.imgs, {
                    transform: "translateY(100%)",
                })
                .set(this.menu, {
                    transform: "translateY(100%)",
                    "border-top-left-radius": "",
                    "border-top-right-radius": "",
                });
        },
        // 更换菜单显示状态
        change_state() {
            // 菜单在进行动画则退出函数
            if (this.if_animating) return;
            if (this.if_visible) this.hidden();
            else this.show();
        },
        // 锁定滚动，当菜单栏进入的时候，页面不可再滚动
        lock_scroll() {
            this.$parent.scroll_controler.stop();
        },
        // 解除锁定，当菜单栏退出的时候，页面恢复滚动
        unlock_scroll() {
            this.$parent.scroll_controler.start();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style>
#menubox {
    position: fixed;
    z-index: 100000000;
}
.menubox_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--theme_white);
    transform: translateY(100%);
    border-top-left-radius: 10rem;
    border-top-right-radius: 10rem;
    background-image: linear-gradient(0deg, transparent 2.9rem, #ddd 3rem),
        linear-gradient(90deg, transparent 2.9rem, #ddd 3rem);
    background-size: 3rem 3rem;
}
@media screen and (max-aspect-ratio: 0.6/1) {
    .menubox_menu {
        border-top-left-radius: 6rem;
        border-top-right-radius: 6rem;
    }
}

.menubox_menu_backg {
    --scale_nums: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(var(--scale_nums) * 65rem);
    height: calc(var(--scale_nums) * 45rem);
    overflow: hidden;
}

.menubox_menu_backg img {
    transform: translateY(100%);
}

.menubox_menu_backg img:nth-child(1) {
    position: absolute;
    top: calc(var(--scale_nums) * 12rem);
    left: calc(var(--scale_nums) * 15rem);
    width: calc(var(--scale_nums) * 28rem);
}

.menubox_menu_backg img:nth-child(2) {
    position: absolute;
    top: 0;
    right: calc(var(--scale_nums) * -2rem);
    width: calc(var(--scale_nums) * 30rem);
}

.menubox_menu_backg img:nth-child(3) {
    position: absolute;
    top: calc(var(--scale_nums) * 32rem);
    left: calc(var(--scale_nums) * 7rem);
    width: calc(var(--scale_nums) * 25rem);
}

.menubox_menu_backg img:nth-child(4) {
    position: absolute;
    bottom: calc(var(--scale_nums) * -45rem);
    right: calc(var(--scale_nums) * -5rem);
    width: calc(var(--scale_nums) * 48rem);
}
.menubox_menu_content {
    --scale_nums: 1;
    position: relative;
    flex-direction: column;
    margin-top: calc(var(--scale_nums) * 5rem);
    margin-left: calc(var(--scale_nums) * 20rem);
}
.mmc_selections {
    flex-direction: column;
    width: calc(var(--scale_nums) * 25rem);
    margin-bottom: calc(var(--scale_nums) * 3rem);
    overflow: hidden;
}

.mmc_selections_selection {
    justify-content: start;
    align-items: center;
    margin-bottom: calc(var(--scale_nums) * 1rem);
    cursor: pointer;
    transform: translateX(-100%);
}
.mmc_selections_selection p:nth-child(1) {
    font-family: zh;
    font-size: calc(var(--scale_nums) * 1rem);
    color: var(--theme_black);
    letter-spacing: calc(var(--scale_nums) * 0.2rem);
    writing-mode: vertical-lr;
    margin-right: calc(var(--scale_nums) * 0.5rem);
    transition: color 0.2s ease;
}
.mmc_selections_selection p:nth-child(2) {
    position: relative;
    font-family: eng;
    font-size: calc(var(--scale_nums) * 4.8rem);
    color: var(--theme_black);
    line-height: calc(var(--scale_nums) * 4.8rem);
    -webkit-text-stroke: calc(var(--scale_nums) * 0.1rem) var(--theme_black);
    text-transform: uppercase;
    transform-origin: left;
    transform: scaleX(1.1);
}

.mmc_selections_selection p:nth-child(2)::before {
    content: attr(text);
    position: absolute;
    font-family: eng;
    font-size: calc(var(--scale_nums) * 4.8rem);
    color: #00000000;
    line-height: calc(var(--scale_nums) * 4.8rem);
    -webkit-text-stroke: calc(var(--scale_nums) * 0.1rem) var(--theme_black);
    transform: translateX(3%);
    transition: color 0.3s ease, -webkit-text-stroke 0.3s ease, transform 0.3s ease;
    z-index: -1;
}

@media (hover: hover) {
    .mmc_selections_selection:hover p:nth-child(1) {
        color: var(--theme_green);
    }
    .mmc_selections_selection:hover p:nth-child(2)::before {
        color: var(--theme_green);
        -webkit-text-stroke: calc(var(--scale_nums) * 0.1rem) var(--theme_green);
        transform: translateX(5%);
    }
}
.mmc_selections_selection_current {
    cursor: default;
}
.mmc_selections_selection_current p:nth-child(1) {
    color: var(--theme_green);
}
.mmc_selections_selection_current p:nth-child(2)::before {
    color: var(--theme_green);
    -webkit-text-stroke: calc(var(--scale_nums) * 0.1rem) var(--theme_green);
    transform: translateX(5%);
}
.mmc_contact {
    position: relative;
    flex-direction: column;
    margin-bottom: calc(var(--scale_nums) * 3rem);
}

.mmc_contact_title {
    font-family: eng;
    font-size: calc(var(--scale_nums) * 1rem);
    color: var(--theme_black);
    letter-spacing: calc(var(--scale_nums) * 0.05rem);
    text-transform: uppercase;
    margin-bottom: calc(var(--scale_nums) * 0.5rem);
}
.mmc_contact_message {
    font-family: zh;
    font-size: calc(var(--scale_nums) * 1rem);
    color: var(--theme_black);
    line-height: calc(var(--scale_nums) * 1rem);
    letter-spacing: calc(var(--scale_nums) * 0.1rem);
    margin-bottom: calc(var(--scale_nums) * 0.6rem);
    user-select: all;
}
.mmc_contact_message span {
    font-size: calc(var(--scale_nums) * 1.1rem);
    color: var(--theme_green);
    user-select: none;
}

.mmc_link {
    position: relative;
    align-self: start;
    font-family: zh;
    font-size: calc(var(--scale_nums) * 0.8rem);
    color: var(--theme_black);
    letter-spacing: calc(var(--scale_nums) * 0.05rem);
    text-decoration: none;
    transition: color 0.3s ease;
}

@media (hover: hover) {
    .mmc_link:hover {
        color: var(--theme_green);
    }
}
@media screen and (max-aspect-ratio: 1.8/1) {
    .menubox_menu_backg {
        --scale_nums: 0.8;
    }
}
@media screen and (max-aspect-ratio: 1.5/1) {
    .menubox_menu_backg {
        --scale_nums: 1;
    }
    .menubox_menu_content {
        --scale_nums: 1.3;
    }
}
@media screen and (max-aspect-ratio: 1.2/1) {
    .menubox_menu_content {
        --scale_nums: 1.5;
        margin-top: calc(var(--scale_nums) * 5rem);
        margin-left: calc(var(--scale_nums) * 15rem);
    }
}
@media screen and (max-aspect-ratio: 1/1) {
    .menubox_menu {
        justify-content: center;
        align-items: center;
    }

    .menubox_menu_content {
        --scale_nums: 1.5;
        margin-top: 0;
        margin-left: 0;
    }
}
@media screen and (max-aspect-ratio: 0.8/1) {
    .menubox_menu_backg {
        --scale_nums: 0.8;
    }
}
@media screen and (max-aspect-ratio: 0.7/1) {
    .menubox_menu_content {
        --scale_nums: 1.5;
        margin-top: calc(var(--scale_nums) * -10rem);
    }
}
.menubox_logo {
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 3.5rem;
    left: 4rem;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    transform: translateY(-50%);
    mix-blend-mode: difference;
}

.menubox_navigation {
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 3.5rem;
    right: 4rem;
    transform: translateY(-50%);
}

.menubox_navigation_menuicon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 2rem;
    cursor: pointer;
    overflow: visible;
}
.menubox_navigation_menuicon circle {
    fill: var(--theme_green);
}

.menubox_navigation_menuicon line {
    stroke: var(--theme_black);
    stroke-width: 6;
    stroke-linecap: round;
    transform-origin: center;
    transition: transform 0.4s ease, stroke-dashoffset 0.4s ease, stroke-dasharray 0.4s ease;
}
.menubox_navigation_menuicon_hidden line:nth-of-type(1) {
    transform: translateY(-13%);
    stroke-dasharray: 13 12 25;
}
.menubox_navigation_menuicon_hidden line:nth-of-type(2) {
    transform: translateY(13%);
    stroke-dasharray: 12.5 0 12.5;
}

.menubox_navigation_menuicon_show line:nth-of-type(1) {
    transform: rotate(45deg);
    stroke-dashoffset: 25;
}

.menubox_navigation_menuicon_show line:nth-of-type(2) {
    transform: rotate(-45deg);
    stroke-dasharray: 2 21 2;
}

@media (hover: hover) {
    .menubox_navigation_menuicon_hidden:hover line:nth-of-type(1) {
        stroke-dashoffset: 25;
    }
    .menubox_navigation_menuicon_show:hover line:nth-of-type(2) {
        stroke-dasharray: 12.5 0 12.5;
    }
}

.menubox_navigation_contact {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 8.5rem;
    height: 2.2rem;
    border: 0.12rem solid var(--theme_white);
    border-radius: 1.5rem;
    transition: background-color 0.4s ease 0.3s;
    cursor: pointer;
    overflow: hidden;
}
.menubox_navigation_contact_black {
    background-color: var(--theme_black);
}

.menubox_navigation_contact p {
    position: absolute;
    font-family: eng;
    font-size: 1.3rem;
    color: var(--theme_white);
    letter-spacing: 0.08rem;
    transition: color 0.4s ease, transform 0.4s ease;
    transform: translateX(-13%);
    z-index: 1;
}
.menubox_navigation_contact svg {
    position: absolute;
    width: 0.9rem;
    height: 0.9rem;
    transform: translateX(320%);
    transition: transform 0.4s ease;
}
.menubox_navigation_contact svg polyline,
.menubox_navigation_contact svg line {
    fill: none;
    stroke: var(--theme_green);
    stroke-width: 8;
    stroke-linecap: round;
}

.menubox_navigation_contact::after,
.menubox_navigation_contact::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    transform: translateX(-100%);
    z-index: 0;
}

.menubox_navigation_contact::after {
    background-color: var(--theme_green);
    transition: transform 0.4s ease 0.1s;
}
.menubox_navigation_contact::before {
    background-color: var(--theme_white);
    transition: transform 0.4s ease;
}

@media (hover: hover) {
    .menubox_navigation_contact:hover::after,
    .menubox_navigation_contact:hover::before {
        transform: translateX(0%);
    }

    .menubox_navigation_contact:hover p {
        color: var(--theme_black);
        transform: translateX(0%);
    }
    .menubox_navigation_contact:hover svg {
        transform: translateX(550%);
    }
}
</style>
