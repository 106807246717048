<template>
    <div id="app">
        <Loading ref="loading" />
        <Menu ref="menu" />
        <router-view></router-view>
    </div>
</template>

<script>
import Loading from "./components/loading.vue";
import Menu from "./components/menu.vue";
import lenis from "lenis";
export default {
    name: "App",
    components: {
        Loading,
        Menu,
    },
    data() {
        return {
            // 页面是否加载完成
            if_loadover: false,
            // lenis：滑动控制器
            scroll_controler: null,
        };
    },
    methods: {
        // 检查登录状态
        check_loading() {
            let timer = setInterval(() => {
                if (document.readyState === "complete") {
                    this.if_loadover = true;
                    clearInterval(timer);
                }
            }, 300);
        },
        // 创建滑动控制器
        create_scroll_controler() {
            this.scroll_controler = new lenis({
                duration: 1,
                easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // 缓动函数
                smoothWheel: true, // 平滑滚轮滑动
            });
            this.scroll_controler.stop(); //滑动锁定
            const raf = (time) => {
                this.scroll_controler.raf(time);
                requestAnimationFrame(raf);
            };
            requestAnimationFrame(raf);
        },
        init_animation() {}, //每一个页面进入时的开屏动画
    },
    mounted() {
        this.create_scroll_controler();
        // 在刷新/进入页面的时候，设置当前页面的标题
        this.$router.onReady(() => {
            this.$router.set_title(this.$route);
        });
        // 配置路由跳转，实现跳转动画
        this.$router.beforeEach((to, from, next) => {
            this.if_loadover = false;
            this.$refs.loading.show(next, to);
        });
        // window.addEventListener("unload", () => {
        //     window.scrollTo(0, 0);
        // });
        this.check_loading();
    },
};
</script>

<style>
* {
    margin: 0;
    padding: 0;
    font-size: 2vmin;
    --theme_black: #171717;
    --theme_white: #f7f7f7;
    --theme_green: #17f700;
}

p {
    user-select: none;
}

img {
    pointer-events: none;
    user-select: none;
}

div {
    display: flex;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
    display: none;
}

::selection {
    background-color: var(--theme_green);
    color: var(--theme_black);
}

video::-webkit-media-controls {
    display: none !important;
}

body {
    width: 100%;
    background-color: var(--theme_black);
}

@media screen and (max-aspect-ratio: 1.5/1) {
    * {
        font-size: 1vmax;
    }
}

@font-face {
    font-family: eng;
    src: url(~@/assets/fonts/impact.ttf);
}

@font-face {
    font-family: zh;
    src: local("思源黑体"), local("阿里巴巴普惠体-R"), local("Microsoft YaHei"),
        local("PingFang SC"), sans-serif;
}
</style>
